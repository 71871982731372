import { useMutation } from '@tanstack/react-query'

import { ContactService } from '../../api/Contact'

const createContact = async (
  params: Parameters<typeof ContactService.sendContact>[0],
) => {
  return ContactService.sendContact(params)
}

export function useCreateContact() {
  return useMutation(createContact)
}
